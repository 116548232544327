import Seo from 'src/components/seo/Seo'

import EcoYoga from '../../components/Campanha/EcoYoga'

function EcoConcepcaoYoga() {
  return (
    <>
      <Seo title="Campanha | Decathlon" />
      <EcoYoga />
    </>
  )
}

export default EcoConcepcaoYoga
